// Body
$primary: #411E5E;
$secondary: #F57A2A;
$bg-primary: #F57A2A ;
$bg-secondary: #411E5E;
$bg-third: #FAFAFA;
$text-white: #ffffff;
$text-black: #272727;
$text-purple: #572586;
$text-gray: #707070;
$text-link: #0153FF;
$success: #1bcc5a;
$yellow: #F5B72A;
$danger: #D62F10;
$background-primary: #ECE8EF;
$background-gray: #F8F8F8;
$border-light-color: #707070;
$siigo_color: #03ABFF;

// Typography
$font-family-sans-serif: sans-serif;
$font-size-base: 0.8rem;
$line-height-base: 1.6;
.text-white{
    color: #ffffff;
}
.bg-success{
    background-color: $success;
}
.bg-secondary{
    background-color: $secondary;
}
.bg-primary{
    background-color: $primary;
}
.text-gray-primary {
    color: $bg-primary;
}
.text-success {
    color: $success;
}
.text-gray-secondary {
    color: $bg-secondary;
}
